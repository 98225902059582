// @flow
import { type CalculatedBillStatusType } from 'types/bills';
import { type SessionStatusType } from 'types/sessions';

export const CLARK_PRIMARY = '#EA4900';
export const CLARK_SECONDARY = '#413E60';
export const CLARK_TERTIARY = '#008489';

export const ERROR_PRIMARY = '#fcece5';

export const CLARK_ACCENT_PRIMARY = '#00CBC4';
export const CLARK_ACCENT_SECONDARY = '#EC8E00';
export const CLARK_ACCENT_TERTIARY = '#FFDFD0';

export const TRANSPARENT = 'transparent';
export const WHITE = '#FFF';
export const GREY_10 = '#ECEBEF';
export const GREY_25 = '#CFCFD7';
export const GREY_50 = '#A09EAF';
export const GREY_75 = '#716f88';
export const BLACK = '#000';

export const LINK_COLOR_PRIMARY = CLARK_TERTIARY;
export const LINK_COLOR_SECONDARY = '#00565a';
export const LINK_COLOR_TERTIARY = GREY_50;
export const LINK_COLOR_QUATERNARY = GREY_75;
export const LINK_COLOR_QUINARY = '#00CBC4';

export const BORDER_COLOR_PRIMARY = GREY_50;
export const BORDER_COLOR_SECONDARY = GREY_75;
export const BORDER_COLOR_TERTIARY = '#00CBC4';

export const BACKGROUND_COLOR_PRIMARY = GREY_10;
export const BACKGROUND_COLOR_SECONDARY = '#E5F9F9';
export const BACKGROUND_COLOR_TERTIARY = '#F9F9FA';

export const BUTTON_COLOR_PRIMARY = CLARK_PRIMARY;
export const BUTTON_COLOR_SECONDARY = '#FF6B18';
export const BUTTON_COLOR_TERTIARY = '#c43d00';

export const OPACITY_50 = '50';
export const OPACITY_25 = '25';

export const BILL_STATUS_COLORS: { [key: CalculatedBillStatusType]: string } = {
  outstanding: CLARK_ACCENT_SECONDARY,
  overdue: BUTTON_COLOR_TERTIARY,
  paid: CLARK_ACCENT_PRIMARY,
  scheduled: CLARK_ACCENT_PRIMARY,
  prepaid: LINK_COLOR_QUINARY,
  unbilled: GREY_25,
};

export const SESSION_STATUS_COLORS: { [key: SessionStatusType]: string } = {
  canceled: CLARK_PRIMARY,
  confirmed: CLARK_ACCENT_PRIMARY,
  pending: CLARK_ACCENT_SECONDARY,
  deleted: BORDER_COLOR_TERTIARY,
};
