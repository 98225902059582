// @flow

import { combineReducers } from 'redux';
import { routerReducer, LOCATION_CHANGE } from 'react-router-redux';

import { localAdminLogout } from 'actions/logout';
import { postAdminLoginSuccess } from 'actions/login';
import { postAdminRegisterSuccess } from 'actions/registration';
import { type ReducerStateType } from 'types/redux';

import applicationReducer from './application';
import formsReducer from './forms';
import resourcesReducer from './resources';

const combinedReducers = combineReducers({
  application: applicationReducer,
  form: formsReducer,
  router: routerReducer,
  resources: resourcesReducer,
});

const rootReducer = (state: ReducerStateType, action: any) => {
  let s = state;

  if (
    [
      LOCATION_CHANGE.toString(),
      localAdminLogout.toString(),
      postAdminLoginSuccess.toString(),
      postAdminRegisterSuccess.toString(),
    ].includes(action.type)
  ) {
    s = {
      application: state.application,
      form: undefined,
      router: undefined,
      resources: undefined,
    };
  }

  return combinedReducers(s, action);
};

export default rootReducer;
