// @flow

/* eslint no-unused-expressions: 0 */
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { type BrowserHistory } from 'history/createBrowserHistory';
import { StripeProvider } from 'react-stripe-elements';

import configureStore from './store';
import Routes from './routes/top-level-routes';
import registerServiceWorker from './registerServiceWorker';
import BugsnagErrorBoundary from './utils/bugsnag';
import hardRefreshIfNecessary from './utils/hard-refresh-if-necessary';

import injectGlobalStyles from './styles/global-styles';
import client from './apollo';

const history: BrowserHistory = createBrowserHistory();
const store = configureStore(history);

class App extends Component<{}, { stripe: ?string }> {
  state = { stripe: null };

  componentDidMount() {
    hardRefreshIfNecessary();

    const stripeApiKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY;

    if (window.Stripe) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        stripe: window.Stripe(stripeApiKey),
      });
    } else {
      // $FlowFixMe
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(stripeApiKey) });
      });
    }
  }

  render() {
    const { stripe } = this.state;
    return (
      <ApolloHooksProvider client={client}>
        <ApolloProvider client={client}>
          <BugsnagErrorBoundary>
            <Provider store={store}>
              <StripeProvider stripe={stripe}>
                <Routes history={history} />
              </StripeProvider>
            </Provider>
          </BugsnagErrorBoundary>
        </ApolloProvider>
      </ApolloHooksProvider>
    );
  }
}
const render = () => {
  ReactDOM.render(
    <App />,
    // $FlowFixMe
    document.getElementById('root'),
  );
};

render();

registerServiceWorker();
injectGlobalStyles();
