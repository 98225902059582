// @flow

import { reducer as reduxFormReducer } from 'redux-form';

import {
  clearLoginForm,
  clearResetPasswordForm,
  clearResetPasswordInitForm,
  clearClientCreateEditForm,
} from '../actions/forms';

const clearState = () => ({});

const formsReducer = reduxFormReducer.plugin({
  login: (state, action) => {
    const actions = {
      [clearLoginForm.toString()]: () => clearState(),
      DEFAULT: () => state,
    };

    return (actions[action.type] || actions.DEFAULT)();
  },
  resetPassword: (state, action) => {
    const actions = {
      [clearResetPasswordForm.toString()]: () => clearState(),
      DEFAULT: () => state,
    };

    return (actions[action.type] || actions.DEFAULT)();
  },
  resetPasswordInit: (state, action) => {
    const actions = {
      [clearResetPasswordInitForm.toString()]: () => clearState(),
      DEFAULT: () => state,
    };

    return (actions[action.type] || actions.DEFAULT)();
  },
  clientCreateEdit: (state, action) => {
    const actions = {
      [clearClientCreateEditForm.toString()]: () => clearState(),
      DEFAULT: () => state,
    };

    return (actions[action.type] || actions.DEFAULT)();
  },
});

export default formsReducer;
