// @flow

import {
  deleteResourceSuccess,
  getResourceSuccess,
  putResourceSuccess,
  postResourceSuccess,
} from 'actions/resources';
import { postAdminRegisterSuccess, postAdminRegisterFailure } from 'actions/registration';
import { postBusinessLogoSuccess } from 'actions/businesses';
import type { ResourcesStateType, ResourcesResponseType } from 'types/resources';
import { handleActions, combineActions } from 'redux-actions';
import deepmerge from 'deepmerge';

const initialState: ResourcesStateType = {
  tutors: {},
  clients: {},
  subjects: {},
  tutoringEngagements: {},
  tutorClientCharges: {},
  sessions: {},
  clientProxies: {},
  clientProxyingArrangements: {},
  smbAdmins: {},
  businesses: {},
  paymentMethods: {},
  firstUseProgresses: {},
  meta: {},
};

// in the case of relationships and meta
// we don't want to duplicate entries in arrays
// just use the latest results. - W.P 9.7.18
const overwriteMerge = (destinationArray, sourceArray) => sourceArray;

const updateStore = (
  state: ResourcesStateType,
  action: { payload: ResourcesResponseType },
): ResourcesStateType => {
  const { status, ...updatedResources } = action.payload;
  return deepmerge(state, updatedResources, { arrayMerge: overwriteMerge });
};

const updateBusinessLogo = (state, { payload }) => {
  const { businessId, logoUrl } = payload;
  return {
    ...state,
    businesses: {
      ...state.businesses,
      [businessId]: {
        ...state.businesses[businessId],
        attributes: {
          ...state.businesses[businessId].attributes,
          logoUrl,
        },
      },
    },
  };
};

const clearCache = (): ResourcesStateType => initialState;

const resourcesReducer = handleActions<ResourcesStateType, any>(
  {
    [combineActions(getResourceSuccess, putResourceSuccess, postResourceSuccess)]: updateStore,
    [combineActions(deleteResourceSuccess)]: clearCache,
    [combineActions(postAdminRegisterSuccess, postAdminRegisterFailure)]: updateStore,
    [postBusinessLogoSuccess.toString()]: updateBusinessLogo,
  },
  initialState,
);

export default resourcesReducer;
