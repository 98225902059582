// @flow

import { createAction } from 'redux-actions';

export const deleteResourceSuccess = createAction<'DELETE_RESOURCE_SUCCESS', any>(
  'DELETE_RESOURCE_SUCCESS',
);

export const getResourceRequest = createAction<'GET_RESOURCE_REQUEST', any>('GET_RESOURCE_REQUEST');
export const getResourceSuccess = createAction<'GET_RESOURCE_SUCCESS', any>('GET_RESOURCE_SUCCESS');
export const getResourceFailure = createAction<'GET_RESOURCE_FAILURE', any>('GET_RESOURCE_FAILURE');

export const postResourceRequest = createAction<'POST_RESOURCE_REQUEST', any>(
  'POST_RESOURCE_REQUEST',
);
export const postResourceSuccess = createAction<'POST_RESOURCE_SUCCESS', any>(
  'POST_RESOURCE_SUCCESS',
);
export const postResourceFailure = createAction<'POST_RESOURCE_FAILURE', any>(
  'POST_RESOURCE_FAILURE',
);

export const putResourceRequest = createAction<'PUT_RESOURCE_REQUEST', any>('PUT_RESOURCE_REQUEST');
export const putResourceSuccess = createAction<'PUT_RESOURCE_SUCCESS', any>('PUT_RESOURCE_SUCCESS');
export const putResourceFailure = createAction<'PUT_RESOURCE_FAILURE', any>('PUT_RESOURCE_FAILURE');
