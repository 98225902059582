// @flow

import normalize from 'json-api-normalizer';
import { localStorageClear } from 'clark-utils';

import ApiError from 'utils/errors/api-error';
import InternalError from 'utils/errors/internal-error';

import calculateRequestPath from 'api/calculate-resource-request-path';

import { type SubjectsType } from 'types/subjects';

import { USER_AUTH } from 'constants/auth-types';
import apolloClient from '../apollo';

export const API_BASE_URL = '/api/smb-admin-web/v1';
export const DEFAULT_HEADERS = { 'Content-Type': 'application/vnd.api+json' };

export type ResponseType = {
  status: number,
};

export const businessPath = (businessId: ?string) => (businessId ? `businesses/${businessId}` : '');
export const businessAdminPath = (adminUserId: string) => `/business-admin-users/${adminUserId}`;

const checkStatus = (response: ResponseType) => {
  const successful = response.status >= 200 && response.status < 300;
  const unprocessable = response.status === 422;

  if (successful || unprocessable) {
    return response;
  }

  if (response.status === 401) {
    localStorageClear(USER_AUTH);
  }

  throw new ApiError(response);
};

const makeRequest = async (endpoint: string, optOverrides = {}): any => {
  const opts = {
    credentials: 'same-origin',
    method: 'GET',
    headers: DEFAULT_HEADERS,
    ...optOverrides,
  };

  try {
    const response = await fetch(`${API_BASE_URL}/${endpoint}`, opts);
    const json = response.status === 204 ? {} : await response.json();

    // temporary solution until refactor
    // need access to errors obj to display inline msg, json-api-normalizer does not handle errors
    // https://github.com/yury-dymov/json-api-normalizer/issues/53
    // J.P. - 02.19.2019
    if (response.status === 422) {
      return { status: response.status, ...json };
    }

    return { status: response.status, ...normalize(json, { endpoint, filterEndpoint: false }) };
  } catch (error) {
    const err = { status: 500, error };
    throw new InternalError(err);
  }
};

export const post = (data: mixed, path: string) =>
  makeRequest(path, { method: 'post', body: JSON.stringify(data) });

export const put = (data: mixed, path: string) =>
  makeRequest(path, { method: 'PUT', body: JSON.stringify(data) });

export const get = (path: string) => makeRequest(path, { method: 'GET' });

// TODO -- add types for these methods
const getHealth = () => get('health');

const postAdminRegister = (data: mixed) => post(data, 'register');
const postAdminLogin = (data: mixed) => post(data, 'login');
const postAdminResetPasswordInit = (data: mixed) => post(data, 'reset-password-init');
const postAdminResetPassword = (data: mixed) => post(data, 'reset-password');

export type SubjectResponseType = {
  status: number,
  subjects: SubjectsType,
};

export type PayoutAccountPayloadType = {
  data: {
    type: 'payout-accounts',
    attributes: {
      accountType: 'company' | 'individual',
      clientIp: string,
      businessName: string,
      businessTaxId: string,
      firstName: string,
      lastName: string,
      birthdayDay: string,
      birthdayMonth: string,
      birthdayYear: string,
      city: string,
      state: string,
      line1: string,
      line2?: string,
      postalCode: string,
      ssnLast4: string,
    },
  },
};

const postPayoutAccountCreate = (data: PayoutAccountPayloadType) => post(data, 'payout-accounts');

const inviteTutors = (ids: Array<string>, businessId: ?string): Promise<ResponseType> => {
  const path = `${businessPath(businessId)}/invite-tutors`;
  const data = {
    data: {
      attributes: {
        tutor_ids: ids,
      },
    },
  };

  return post(data, path);
};

const updateGoogleCalendarSubscriptions = (
  subscribeToIds: string[],
  unsubscribeFromIds: string[],
): Promise<ResponseType> => {
  const path = 'google-calendar-subscriptions';
  const data = {
    data: {
      attributes: {
        subscribe_to_tutor_ids: subscribeToIds,
        unsubscribe_from_tutor_ids: unsubscribeFromIds,
      },
    },
  };

  return post(data, path);
};

const isLinkValid = async (businessId: string, businessAdminUserId: string): Promise<boolean> => {
  const path = `${API_BASE_URL}/validate-link/?business_id=${businessId}&business_admin_user_id=${businessAdminUserId}`;
  const response = await fetch(path, { method: 'GET' });
  checkStatus(response);
  const { valid } = await response.json();
  return valid;
};

const postAdminConfirmEmail = (emailConfirmationPin: string, adminUserId: string): Promise<any> => {
  const path = `${businessAdminPath(adminUserId)}/confirm`;
  const data = {
    data: {
      attributes: {
        email_confirmation_pin: Number(emailConfirmationPin),
      },
    },
  };
  return post(data, path);
};

const postAdminResendPin = (adminUserId: string): Promise<any> => {
  const path = `${businessAdminPath(adminUserId)}/resend`;
  const data = {
    data: {
      attributes: { can_resend_email: true },
    },
  };
  return post(data, path);
};

const inviteBusinessAdmins = (ids: Array<string>, businessId?: string): Promise<ResponseType> => {
  const path = `${businessPath(businessId)}/invite-admins`;
  const data = {
    data: {
      attributes: {
        admin_ids: ids,
      },
    },
  };

  return post(data, path);
};

export {
  checkStatus,
  getHealth,
  isLinkValid,
  calculateRequestPath,
  postAdminConfirmEmail,
  postAdminResendPin,
  postAdminRegister,
  postAdminLogin,
  postAdminResetPasswordInit,
  postAdminResetPassword,
  postPayoutAccountCreate,
  inviteBusinessAdmins,
  inviteTutors,
  updateGoogleCalendarSubscriptions,
  apolloClient,
};
