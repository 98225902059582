// @flow

import { createAction } from 'redux-actions';

export const clearLoginForm = createAction<'CLEAR_LOGIN_FORM', any>('CLEAR_LOGIN_FORM');
export const clearResetPasswordForm = createAction<'CLEAR_RESET_PASSWORD_FORM', any>(
  'CLEAR_RESET_PASSWORD_FORM',
);
export const clearResetPasswordInitForm = createAction<'CLEAR_RESET_PASSWORD_INIT_FORM', any>(
  'CLEAR_RESET_PASSWORD_INIT_FORM',
);
export const clearClientCreateEditForm = createAction<'CLEAR_CLIENT_CREATE_EDIT_FORM', any>(
  'CLEAR_CLIENT_CREATE_EDIT_FORM',
);
