// @flow

import { createAction } from 'redux-actions';
import { push } from 'react-router-redux';
import { localStorageSet } from 'clark-utils';
import get from 'lodash/get';

import { clearLoginForm } from 'actions/forms';
import {
  showFlashBanner,
  addApplicationLoader,
  removeApplicationLoader,
} from 'actions/application';

import type { DispatchType, GetStateType, ApiType } from 'types/redux';

import { USER_AUTH } from 'constants/auth-types';
import { FLASH_MESSAGES } from 'constants/flash-banner';

export const postAdminLoginRequest = createAction<'POST_ADMIN_LOGIN_REQUEST', any>(
  'POST_ADMIN_LOGIN_REQUEST',
);
export const postAdminLoginSuccess = createAction<'POST_ADMIN_LOGIN_SUCCESS', any>(
  'POST_ADMIN_LOGIN_SUCCESS',
);
export const postAdminLoginFailure = createAction<'POST_ADMIN_LOGIN_FAILURE', any>(
  'POST_ADMIN_LOGIN_FAILURE',
);

export const postAdminResetPasswordInitRequest = createAction<
  'POST_ADMIN_RESET_PASSWORD_INIT_REQUEST',
  any,
>('POST_ADMIN_RESET_PASSWORD_INIT_REQUEST');
export const postAdminResetPasswordInitSuccess = createAction<
  'POST_ADMIN_RESET_PASSWORD_INIT_SUCCESS',
  any,
>('POST_ADMIN_RESET_PASSWORD_INIT_SUCCESS');
export const postAdminResetPasswordInitFailure = createAction<
  'POST_ADMIN_RESET_PASSWORD_INIT_FAILURE',
  any,
>('POST_ADMIN_RESET_PASSWORD_INIT_FAILURE');

export const postAdminResetPasswordRequest = createAction<'POST_ADMIN_RESET_PASSWORD_REQUEST', any>(
  'POST_ADMIN_RESET_PASSWORD_REQUEST',
);
export const postAdminResetPasswordSuccess = createAction<'POST_ADMIN_RESET_PASSWORD_SUCCESS', any>(
  'POST_ADMIN_RESET_PASSWORD_SUCCESS',
);
export const postAdminResetPasswordFailure = createAction<'POST_ADMIN_RESET_PASSWORD_FAILURE', any>(
  'POST_ADMIN_RESET_PASSWORD_FAILURE',
);

export const postAdminLogin = (data: any) => async (
  dispatch: DispatchType,
  getState: GetStateType,
  api: ApiType,
) => {
  dispatch(addApplicationLoader());
  dispatch(postAdminLoginRequest());
  try {
    const response = await api.postAdminLogin(data);
    api.checkStatus(response);

    const { smbAdmins, businesses = {}, clarkSuperAdmins, tutors } = response;

    let resourceResponse;
    let authType;

    if (tutors) {
      resourceResponse = tutors;
      authType = 'tutorUser';
    } else if (clarkSuperAdmins) {
      resourceResponse = clarkSuperAdmins;
      authType = 'clarkSuperAdminUser';
    } else {
      resourceResponse = smbAdmins;
      authType = 'adminUser';
    }

    const id = Object.keys(resourceResponse)[0];
    const user = resourceResponse[id];
    const businessId = Object.keys(businesses)[0];
    const business = businesses[businessId];
    const businessOnboardingMethod = get(business, 'attributes.onboardingMethod');
    const logoUrl = get(business, 'attributes.logoUrl');
    const businessName = get(business, 'attributes.name');
    const isIndividualTutor = get(business, 'attributes.isIndividualTutor');

    const formattedResponse = {
      id,
      logoUrl,
      businessId,
      businessName,
      businessOnboardingMethod,
      isIndividualTutor,
      ...user.attributes,
    };

    const authHash = { type: authType, ...formattedResponse };
    localStorageSet(USER_AUTH, authHash);

    dispatch(postAdminLoginSuccess(formattedResponse));
    dispatch(removeApplicationLoader());
    dispatch(clearLoginForm());

    if (authType === 'clarkSuperAdminUser') {
      dispatch(push('/businesses'));
    } else {
      dispatch(push('/bills'));
    }
  } catch (error) {
    dispatch(postAdminLoginFailure(error));
    dispatch(showFlashBanner(FLASH_MESSAGES.failedLogin()));
    dispatch(removeApplicationLoader());
  }
};

export const postAdminSendResetPasswordEmail = (data: any) => async (
  dispatch: DispatchType,
  getState: GetStateType,
  api: ApiType,
) => {
  dispatch(postAdminResetPasswordInitRequest());
  dispatch(addApplicationLoader());
  try {
    const response = await api.postAdminResetPasswordInit(data);
    api.checkStatus(response);

    dispatch(postAdminResetPasswordInitSuccess({}));
    dispatch(showFlashBanner(FLASH_MESSAGES.successfulResetPasswordInit()));
    dispatch(removeApplicationLoader());
  } catch (error) {
    dispatch(postAdminResetPasswordInitFailure(error));
    dispatch(showFlashBanner(FLASH_MESSAGES.failedResetPasswordInit()));
    dispatch(removeApplicationLoader());
  }
};

export const postAdminResetPassword = (data: any) => async (
  dispatch: DispatchType,
  getState: GetStateType,
  api: ApiType,
) => {
  dispatch(postAdminResetPasswordRequest());
  dispatch(addApplicationLoader());
  try {
    const response = await api.postAdminResetPassword(data);
    api.checkStatus(response);

    dispatch(postAdminResetPasswordSuccess({}));
    dispatch(showFlashBanner(FLASH_MESSAGES.successfulResetPassword()));
    dispatch(removeApplicationLoader());
    dispatch(push('/login'));
  } catch (error) {
    dispatch(postAdminResetPasswordFailure(error));
    dispatch(showFlashBanner(FLASH_MESSAGES.failedResetPassword()));
    dispatch(removeApplicationLoader());
  }
};
