// @flow

import React, { lazy, Suspense } from 'react';

import { type BrowserHistory } from 'history/createBrowserHistory';

import Loader from 'components/loader';

// $FlowFixMe
const SMBRoutes = lazy(() => import('routes/index'));
const StudentRoutes = lazy(() => import('src/student-experience/routes'));

const RoutesToRender = ({ history }: { history: BrowserHistory }) => {
  switch (process.env.REACT_APP_EXPERIENCE_TYPE) {
    case 'smb':
      return <SMBRoutes history={history} />;
    case 'student':
      return <StudentRoutes history={history} />;
    default:
      return (
        <div>Woops: It looks like you didnt set the REACT_APP_EXPERIENCE_TYPE env variable!</div>
      );
  }
};

const Routes = ({ history }: { history: BrowserHistory }) => (
  <Suspense fallback={<Loader />}>
    <RoutesToRender history={history} />
  </Suspense>
);

export default Routes;
