// @flow

import { EventTypes } from 'redux-segment';

const trackAdminLogout = () => [
  {
    eventType: EventTypes.track,
    eventPayload: {
      event: 'SMB Admin Logs Out',
    },
  },
  {
    eventType: EventTypes.reset,
  },
];

export default trackAdminLogout;
