// @flow

import React from 'react';
import bugsnag from 'bugsnag-js';
import createPlugin from 'bugsnag-react';
import { getFullName, localStorageGet } from 'clark-utils';

import { USER_AUTH } from 'constants/auth-types';

export const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  notifyReleaseStages: ['production'],
  beforeSend: report => {
    const user = localStorageGet(USER_AUTH) || {};
    const { id, type, email, businessId, businessName } = user;
    const name = getFullName(user);
    // eslint-disable-next-line
    report.user = {
      id,
      type,
      email,
      name,
      businessId,
      businessName,
    };
  },
});
export default bugsnagClient.use(createPlugin(React));
