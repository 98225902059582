// @flow

import { createAction } from 'redux-actions';

export const postBusinessLogoRequest = createAction<'POST_BUSINESS_LOGO_REQUEST', any>(
  'POST_BUSINESS_LOGO_REQUEST',
);

export const postBusinessLogoSuccess = createAction<'POST_BUSINESS_LOGO_SUCCESS', any>(
  'POST_BUSINESS_LOGO_SUCCESS',
);

export const postBusinessLogoFailure = createAction<'POST_BUSINESS_LOGO_FAILURE', any>(
  'POST_BUSINESS_LOGO_FAILURE',
);
