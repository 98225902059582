/* eslint flowtype/require-valid-file-annotation: 0 */

class ApiError extends Error {
  constructor({ url, status, detail, title }) {
    super();
    const message = detail || title;
    const err = Error(`${status}: ${message}`);

    this.name = 'ApiError';
    this.url = url;
    this.status = status;
    this.message = message;
    this.stack = err.stack;
    this.stackTrace = err.stack;
  }
}

export default ApiError;
