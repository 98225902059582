// @flow

const getSegmentIds = () => {
  let userId;
  let anonymousId;

  window.analytics.ready(() => {
    const user = window.analytics.user();
    userId = user.id();
    anonymousId = user.anonymousId();
  });

  return {
    segmentUserId: userId,
    segmentAnonymousId: anonymousId,
  };
};

export default getSegmentIds;
