// @flow

import { EventTypes } from 'redux-segment';
import get from 'lodash/get';
import { getFirstElement } from 'clark-utils';
import getSegmentIds from 'utils/helpers/segment';

import type { ResourcesStateType } from 'types/resources';
import type { BusinessAdminType } from 'types/business-admins';
import { identifyUser } from 'actions/tracking/user-identify';

const aliasIdentifyAndTrackUser = (
  state: ResourcesStateType,
  { payload: { smbAdmins } }: { payload: { smbAdmins: BusinessAdminType } },
) => {
  const user = getFirstElement(smbAdmins);
  const userId = get(user, 'id');
  const { firstName, lastName, email } = user.attributes;
  const { segmentAnonymousId: previousId } = getSegmentIds();

  const alias = {
    eventType: EventTypes.alias,
    eventPayload: {
      userId,
      previousId,
    },
  };

  const identify = identifyUser(email, firstName, lastName, userId);

  const track = {
    eventType: EventTypes.track,
    eventPayload: {
      event: 'Alias Segment Id With Uuid',
    },
  };

  return [alias, identify, track];
};

export default aliasIdentifyAndTrackUser;
