/* eslint global-require: 0, flowtype/require-valid-file-annotation: 0 */

import { compose, applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import segmentTracker from './middleware/segment';

import rootReducer from '../reducers';
import * as api from '../api';

export default function configureStore(history) {
  const router = routerMiddleware(history);
  const logger = createLogger({ collapsed: true, duration: true });
  const store = createStore(
    rootReducer,
    compose(applyMiddleware(thunk.withExtraArgument(api), router, segmentTracker, logger)),
  );

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
