/* eslint flowtype/require-valid-file-annotation: 0 */

import get from 'lodash/get';

class InternalError extends Error {
  constructor(response: any) {
    super();
    const status = get(response, 'status');
    const detail = get(response, 'errors[0].detail');
    const title = get(response, 'errors[0].title');

    const message = detail || title;
    const err = Error(message);

    this.status = status || 500;
    this.name = 'InternalError';
    this.stack = err.stack;
  }
}

export default InternalError;
