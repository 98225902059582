// @flow
import { localStorageGet, localStorageSet } from 'clark-utils';
import moment from 'moment';

const REFRESH_KEY = 'LAST_HARD_REFRESH';

const hardRefreshIfNecessary = () => {
  const { lastRefresh } = localStorageGet(REFRESH_KEY) || {};

  const now = moment();
  const daysSinceLastRefresh = moment(now).diff(lastRefresh, 'days');

  if (!lastRefresh || daysSinceLastRefresh > 5) {
    window.location.reload(true);
    localStorageSet(REFRESH_KEY, { lastRefresh: now.toISOString() });
  }
};

export default hardRefreshIfNecessary;
