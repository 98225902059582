// @flow

import React from 'react';

import styled from 'styled-components';

import { CLARK_PRIMARY } from 'styles/colors';
import { SPACING_EXTRA_EXTRA_LARGE } from 'styles/spacing';
import { Z_INDEX_TOP } from 'styles/z-index';

type PropsType = { color?: string, size?: string };

export const Spinner = ({ color = CLARK_PRIMARY, size = '50px' }: PropsType) => (
  <StyledSpinner viewBox="0 0 50 50" color={color} size={size}>
    <circle className="path" strokeWidth="4" fill="none" cx="25" cy="25" r="20" />
  </StyledSpinner>
);

const Loader = ({ color = CLARK_PRIMARY, size = '50px', ...rest }: PropsType) => (
  <LoaderOverlayContainer data-testid="loader-overlay" {...rest}>
    <LoaderOverlay>
      <LoaderIconContainer>
        <Spinner color={color} size={size} />
      </LoaderIconContainer>
    </LoaderOverlay>
  </LoaderOverlayContainer>
);

export default Loader;

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  margin: -25px 0 0 -25px;
  width: ${props => props.size};
  height: ${props => props.size};

  & .path {
    stroke: ${props => props.color};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }

    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const LoaderOverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${Z_INDEX_TOP};
`;

const LoaderOverlay = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const LoaderIconContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${SPACING_EXTRA_EXTRA_LARGE};
  justify-content: center;
  width: ${SPACING_EXTRA_EXTRA_LARGE};
`;
