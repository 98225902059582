// @flow

import { EventTypes } from 'redux-segment';

import { localStorageGet } from 'clark-utils';
import { USER_AUTH } from 'constants/auth-types';

const emailsForInternalUseRegex = /hiclark|example|no-reply/i;

const emailIsClearlyForInternalUse = (email: string): boolean =>
  !!email.match(emailsForInternalUseRegex);

const segmentIntegrations = (email: string) => {
  const integrations = {};

  if (emailIsClearlyForInternalUse(email)) {
    integrations.AutopilotHQ = false;
  }

  return integrations;
};

export const identifyUserFromLocalStorage = () => {
  const auth = localStorageGet(USER_AUTH);
  // handle potentially empty user_auth cookie in local storage
  if (!auth) return [];

  const { id: userId, firstName, lastName, email } = auth;

  return [
    {
      eventType: EventTypes.identify,
      eventPayload: {
        userId,
        traits: {
          name: `${firstName} ${lastName}`,
          email,
        },
        integrations: segmentIntegrations(email),
      },
    },
  ];
};

export const identifyUser = (
  email: string,
  firstName: string,
  lastName: string,
  userId: string,
) => ({
  eventType: EventTypes.identify,
  eventPayload: {
    userId,
    traits: {
      name: `${firstName} ${lastName}`,
      email,
    },
    integrations: segmentIntegrations(email),
  },
});
