// @flow
import { createAction } from 'redux-actions';

export const postAdminRegisterRequest = createAction<'POST_ADMIN_REGISTER_REQUEST', any>(
  'POST_ADMIN_REGISTER_REQUEST',
);
export const postAdminRegisterSuccess = createAction<'POST_ADMIN_REGISTER_SUCCESS', any>(
  'POST_ADMIN_REGISTER_SUCCESS',
);
export const postAdminRegisterFailure = createAction<'POST_ADMIN_REGISTER_FAILURE', any>(
  'POST_ADMIN_REGISTER_FAILURE',
);

export const getValidateLinkRequest = createAction<'GET_VALIDATE_LINK_REQUEST', any>(
  'GET_VALIDATE_LINK_REQUEST',
);
export const getValidateLinkSuccess = createAction<'GET_VALIDATE_LINK_SUCCESS', any>(
  'GET_VALIDATE_LINK_SUCCESS',
);
export const getValidateLinkFailure = createAction<'GET_VALIDATE_LINK_FAILURE', any>(
  'GET_VALIDATE_LINK_FAILURE',
);
