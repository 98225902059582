// @flow

import { type ResourceNameType } from 'types/resources';
import compact from 'lodash/compact';

export type PageQueryObjectType = {
  pageNumber: number,
  pageSize: number,
};

const endpointsMap = {
  tutors: 'tutors',
  clients: 'clients',
  subjects: 'subjects',
  tutoringEngagements: 'tutoring-engagements',
  tutorClientCharges: 'tutor-client-charges',
  sessions: 'sessions',
  clientProxies: 'client-proxies',
  clientProxyingArrangements: 'client-proxying-arrangements',
  businesses: 'businesses',
  smbAdmins: 'smb-admins',
  paymentMethods: 'payment-methods',
  firstUseProgresses: 'first-use-progresses',
  businessReportRequests: 'business-report-requests',
};

type RequestType = 'PUT' | 'POST' | 'GET';

type CalculateQueryStringArgsType = {|
  includes?: string[],
  page?: PageQueryObjectType,
  sort?: string,
  // Note: This can be refactored to take an object of keys and values later
  filterString?: string,
|};

export const calculateQueryString = ({
  includes,
  page: { pageNumber, pageSize } = { pageNumber: 1, pageSize: 500 },
  filterString,
  sort,
}: CalculateQueryStringArgsType): string => {
  const includeString = includes && includes.length > 0 ? `include=${includes.join(',')}` : '';
  const pageString = `page[number]=${pageNumber}&page[size]=${pageSize}`;
  const sortString = sort ? `sort=${sort}` : '';
  return compact([filterString, includeString, sortString, pageString]).join('&');
};

type CalculateRequestPathArgsType = {|
  resource: ResourceNameType,
  resourceId?: string,
  scopedToResource?: { resource: ResourceNameType, id: string },
  businessId?: ?string,
  includes?: string[],
  page?: PageQueryObjectType,
  sort?: string,
  requestType?: RequestType,
  // Note: This can be refactored to take an object of keys and values later
  filterString?: string,
|};

const calculateRequestPath = ({
  resource,
  resourceId,
  scopedToResource,
  businessId,
  includes,
  page = { pageNumber: 1, pageSize: 500 },
  filterString,
  sort,
  requestType = 'GET',
}: CalculateRequestPathArgsType): string => {
  const scopedToResourceString = scopedToResource
    ? `${endpointsMap[scopedToResource.resource]}/${scopedToResource.id}`
    : '';
  const filterByBusinessString = businessId ? `businesses/${businessId}` : '';
  const basePath = compact([
    filterByBusinessString,
    scopedToResourceString,
    endpointsMap[resource],
    resourceId,
  ]).join('/');

  if (requestType === 'GET') {
    const queryString = calculateQueryString({ includes, page, filterString, sort });

    return compact([basePath, queryString]).join('?');
  }
  return basePath;
};

export default calculateRequestPath;

export const splitPathAndQueryString = (str: string) => str.split(/(?=\?)/g);
