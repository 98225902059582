// @flow

import {
  CLARK_ACCENT_TERTIARY,
  BACKGROUND_COLOR_SECONDARY,
  CLARK_TERTIARY,
  CLARK_PRIMARY,
} from 'styles/colors';

import type { ClientAttributesType } from 'types/clients';

const GENERIC_MESSAGE = 'If this issue persists please contact help@hiclark.com.';

// this is an obvious refactor target;
// this list can't just keep growing in place
export const FLASH_MESSAGES = {
  failedLogin: () => ({
    status: 401,
    headline: `Could not log in with those credentials`,
    messageBody: 'Please try again',
  }),
  failedRegistration: () => ({
    status: 400,
    headline: `Sorry! Something went wrong when trying to create an account.`,
    messageBody: 'Refresh and please try again',
  }),
  failedEmailConfirmation: () => ({
    status: 400,
    headline: `Sorry! Something went wrong when trying to confirm your email.`,
    messageBody: 'Refresh and please try again',
  }),
  failedEmailConfirmationResend: () => ({
    status: 400,
    headline: `Sorry! Something went wrong when trying to resend email confirmation pin.`,
    messageBody: 'Refresh and please try again',
  }),
  clientCreateSuccess: (client: ClientAttributesType) => ({
    status: 200,
    headline: `Saved ${client.firstName}`,
    messageBody: 'Good job!',
  }),
  clientCreateFailure: () => ({
    status: 401,
    headline: `Could not create client`,
  }),
  clientUpdateSuccess: (client: ClientAttributesType) => ({
    status: 200,
    headline: `Saved ${client.firstName}`,
    messageBody: 'Good job!',
  }),
  clientUpdateFailure: () => ({
    status: 401,
    headline: 'Could not update client',
    messageBody: 'Please try again',
  }),
  successfulLogin: () => ({
    status: 200,
    headline: `You're logged in!`,
    messageBody: 'All set',
  }),
  failedResetPasswordInit: () => ({
    status: 401,
    headline: `Could not log in with those credentials`,
    messageBody: 'Please try again',
  }),
  successfulResetPasswordInit: () => ({
    status: 200,
    headline: `Email sent!`,
    messageBody: 'Click the link in your email to set your new password',
  }),
  successfulResetPassword: () => ({
    status: 200,
    headline: `Password has been set`,
    messageBody: 'Great!',
  }),
  successfulResendInvoice: () => ({
    status: 200,
    headline: `Invoice has been resent!`,
    messageBody: 'Great!',
  }),
  failedResetPassword: () => ({
    status: 401,
    headline: `Could not log in with those credentials`,
    messageBody: 'Please try again',
  }),
  stripeTokenError: (msg: string) => ({
    status: 500,
    headline: `There is an error with your credentials`,
    messageBody: `${msg}`,
  }),
  payoutAccountCreationError: () => ({
    status: 500,
    headline: 'There was an error updating your account.',
    messageBody: `Please contact customer support for assistance.`,
  }),
  customError: () => ({
    status: 500,
    headline: `Custom error`,
    messageBody: 'Please try again',
  }),
  customSuccess: () => ({
    status: 200,
    headline: `Everything is great!`,
    messageBody: 'All set',
  }),
  billCancelationSuccess: {
    status: 200,
    headline: 'Canceled!',
    messageBody: 'Any logged sessions on that invoice will now appear as unbilled.',
  },
  sessionDeletionSuccess: (studentName: string) => ({
    status: 200,
    headline: `${studentName}'s session has been deleted`,
  }),
  engagementDeletionSuccess: (studentName: string) => ({
    status: 200,
    headline: `The enagement with ${studentName} has been deleted`,
  }),
  sessionCancelSuccess: () => ({
    status: 200,
    headline: `Session canceled!`,
  }),
  generateReportSuccess: {
    status: 201,
    headline: `Success! Check your email in a few minutes for the download.`,
  },
  isInvalidLink: {
    status: 401,
    headline: `Sorry! Looks like this link is either expired or doesn’t exist!`,
  },
  payrollSweepError: (err: string) => ({
    status: 401,
    headline: `${err}`,
  }),
  clientTermsSuccess: {
    status: 200,
    headline: 'The client terms & conditions has been enabled.',
  },
  clientTermsDisabledSuccess: {
    status: 200,
    headline: 'The client terms & conditions has been disabled.',
  },
};

const successColorScheme = {
  backgroundColor: BACKGROUND_COLOR_SECONDARY,
  buttonColor: CLARK_TERTIARY,
};

const failureColorScheme = {
  backgroundColor: CLARK_ACCENT_TERTIARY,
  buttonColor: CLARK_PRIMARY,
};

/* eslint no-useless-computed-key: 0 */
export const DEFAULTS = {
  [200]: {
    colorScheme: successColorScheme,
    headline: `Success!`,
  },
  [201]: {
    colorScheme: successColorScheme,
    headline: `Success!`,
  },
  [400]: {
    colorScheme: failureColorScheme,
    headline: 'Sorry! Something went wrong. Please refresh and try again.',
    messageBody: `${GENERIC_MESSAGE}`,
  },
  [401]: {
    colorScheme: failureColorScheme,
    headline: 'Please log in',
    messageBody: `${GENERIC_MESSAGE}`,
  },
  [404]: {
    colorScheme: failureColorScheme,
    headline: 'Sorry! The page you were looking for could not be found.',
    messageBody: `${GENERIC_MESSAGE}`,
  },
  [409]: {
    colorScheme: failureColorScheme,
    headline: "Looks like you already have one of those, and we can't create another.",
    messageBody: `${GENERIC_MESSAGE}`,
  },
  [500]: {
    colorScheme: failureColorScheme,
    headline: `Sorry! We are experiencing server issues.`,
    messageBody: `${GENERIC_MESSAGE}`,
  },
  [503]: {
    colorScheme: failureColorScheme,
    headline: `Sorry! We are experiencing server issues.`,
    messageBody: `${GENERIC_MESSAGE}`,
  },
};
