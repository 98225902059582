// @flow

import { createAction } from 'redux-actions';
import businessId from 'utils/business-id';

export const hideFlashBanner = createAction<'HIDE_FLASH_BANNER', any>('HIDE_FLASH_BANNER');
export const showFlashBanner = createAction<'SHOW_FLASH_BANNER', any>('SHOW_FLASH_BANNER');

export const addApplicationLoader = createAction<'ADD_LOADER', any>('ADD_LOADER');
export const removeApplicationLoader = createAction<'REMOVE_LOADER', any>('REMOVE_LOADER');

export const updateActiveTab = createAction<'SET_ACTIVE_TAB', any>('SET_ACTIVE_TAB');

// WRITES TO LOCAL STORAGE AND THEN DOES NOTHING
export const updateAdminBusinessId = createAction<'UPDATE_ADMIN_BUSINESS_ID', string[], string>(
  'UPDATE_ADMIN_BUSINESS_ID',
  (id: string) => {
    businessId.set(id);
    return id;
  },
);
