// @flow

import {
  showFlashBanner,
  hideFlashBanner,
  addApplicationLoader,
  removeApplicationLoader,
  updateActiveTab,
} from 'actions/application';
import {
  getResourceFailure,
  putResourceRequest,
  putResourceSuccess,
  putResourceFailure,
  postResourceRequest,
  postResourceSuccess,
  postResourceFailure,
} from 'actions/resources';
import { handleActions, combineActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'react-router-redux';

type StateType = {
  loaders: number,
  flash: any,
  activeTab: number,
  currentLocation: ?string,
  previousLocation: ?string,
};

const initialState: StateType = {
  loaders: 0,
  flash: null,
  activeTab: 0,
  previousLocation: undefined,
  currentLocation: undefined,
};

const setActiveTab = (state: StateType, action): StateType => ({
  ...state,
  activeTab: action.payload,
});

const incrementLoaders = (state: StateType): StateType => ({
  ...state,
  loaders: state.loaders + 1,
});

const decrementLoaders = (state: StateType): StateType => ({
  ...state,
  loaders: state.loaders < 1 ? 0 : state.loaders - 1,
});

const showFlash = (state: StateType, action): StateType => ({
  ...state,
  flash: { ...action.payload },
});

const hideFlash = (state: StateType): StateType => ({
  ...state,
  flash: null,
});

const decrementLoadersAndShowFlash = (state: StateType, action): StateType =>
  showFlash(decrementLoaders(state), action);

const resetAndStorePreviousLocation = (state: StateType, action) => ({
  ...initialState,
  previousLocation: state.currentLocation,
  currentLocation: action.payload.pathname,
});

const applicationReducer = handleActions<StateType, any>(
  {
    [LOCATION_CHANGE.toString()]: resetAndStorePreviousLocation,

    [updateActiveTab.toString()]: setActiveTab,

    [combineActions(showFlashBanner, getResourceFailure)]: showFlash,
    [hideFlashBanner.toString()]: hideFlash,

    [addApplicationLoader.toString()]: incrementLoaders,
    [removeApplicationLoader.toString()]: decrementLoaders,

    [combineActions(postResourceRequest, putResourceRequest)]: incrementLoaders,
    [combineActions(putResourceSuccess, postResourceSuccess)]: decrementLoaders,
    [combineActions(putResourceFailure, postResourceFailure)]: decrementLoadersAndShowFlash,
  },
  initialState,
);

export default applicationReducer;
