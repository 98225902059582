// @flow

const ADMIN_BUSINESS_ID = 'ADMIN_BUSINESS_ID';

const businessIdUtil = {
  set: (id: string) => localStorage.setItem(ADMIN_BUSINESS_ID, id),
  get: () => localStorage.getItem(ADMIN_BUSINESS_ID) || '',
  clear: () => localStorage.removeItem(ADMIN_BUSINESS_ID),
};

export default businessIdUtil;
