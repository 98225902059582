// @flow

import { createTracker, EventTypes } from 'redux-segment';
import { LOCATION_CHANGE } from 'react-router-redux';

import { postAdminLoginSuccess } from 'actions/login';
import { localAdminLogout } from 'actions/logout';
import { postAdminRegisterSuccess } from 'actions/registration';

import aliasIdentifyAndTrackUser from 'actions/tracking/user-alias';
import { identifyUserFromLocalStorage } from 'actions/tracking/user-identify';
import trackAdminLogout from 'actions/tracking/user-logout';

const customSegmentMapper = {
  mapper: {
    [LOCATION_CHANGE]: EventTypes.page,
    [postAdminLoginSuccess.toString()]: identifyUserFromLocalStorage,
    [localAdminLogout.toString()]: trackAdminLogout,
    [postAdminRegisterSuccess.toString()]: aliasIdentifyAndTrackUser,
  },
};

const segmentTracker = createTracker(customSegmentMapper);

export default segmentTracker;
