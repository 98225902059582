// @flow
/* eslint import/prefer-default-export: 0 */

import { createAction } from 'redux-actions';
import { push } from 'react-router-redux';
import { localStorageClear } from 'clark-utils';

import { type DispatchType } from 'types/redux';
import businessId from 'utils/business-id';

import { USER_AUTH } from 'constants/auth-types';

export const localAdminLogout = createAction<'LOCAL_ADMIN_LOGOUT', any>('LOCAL_ADMIN_LOGOUT');

export const adminLogout = () => (dispatch: DispatchType) => {
  localStorageClear(USER_AUTH);
  businessId.clear();
  dispatch(localAdminLogout());
  dispatch(push('/login'));
};
